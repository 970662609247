<template>
	<v-container fluid>
		<LandingPricing />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('nav.pricing')
		}
	},
	components: {
		LandingPricing: () => import('@/components/landing/LandingPricing.vue')
	}
}
</script>
